<template>
  <div class="row align-items-center mb-4">
    <div class="col-12">
      <h4 class="font-weight-bold py-3 mb-0">
        <i class="fas fa-user-tie" /> {{ $t('transferAgentHead.title') }} <span class="text-muted font-weight-light">| {{ pageName }}</span>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransferAgentHead',
  props: {
    pageName: {
      type: String,
      default: '',
    },
  },
};
</script>
