export const defaultFields = [
  { key: 'createdAt', i18nKey: 'controlBook.label.table.createdAt', tdClass: 'align-middle' },
  { key: 'authoriser', i18nKey: 'controlBook.label.table.authorizer', tdClass: 'align-middle' },
  { key: 'operator.name', i18nKey: 'controlBook.label.table.operatorName', tdClass: 'align-middle' },
  { key: 'previousAuthorizedSecurities', i18nKey: 'controlBook.label.table.previousAuthorizedSecurities', tdClass: 'align-middle' },
  { key: 'authorizedSecurities', i18nKey: 'controlBook.label.table.authorizedSecurities', tdClass: 'align-middle' },
  { key: 'previousCbSecurities', i18nKey: 'controlBook.label.table.previousCbSecurities', tdClass: 'align-middle' },
  { key: 'cbSecurities', i18nKey: 'controlBook.label.table.cbSecurities', tdClass: 'align-middle' },
  { key: 'description', i18nKey: 'controlBook.label.table.description', tdClass: 'align-middle' },
  {
    key: 'documentationId', i18nKey: 'controlBook.label.table.documentationId', formatter: val => val || '-', tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'align-middle' },
];
export const tokenIsDebtValueLabels = {
  previousAuthorizedSecurities: 'controlBook.label.table.previousPrincipalAmountAuthorizedSecurities',
  authorizedSecurities: 'controlBook.label.table.principalAmountauthorizedSecurities',
  previousCbSecurities: 'controlBook.label.table.previousCbPrincipalAmountSecurities',
  cbSecurities: 'controlBook.label.table.cbPrincipalAmountSecurities',
};
