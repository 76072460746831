<template>
  <div class="row">
    <template v-if="isReady">
      <div class="col-md-6 d-md-flex align-items-stretch">
        <div class="card w-100 mb-3 mb-md-0">
          <div class="card-body text-center">
            <h2 class="mb-1">
              <strong :class="{'color-red': summary.authorizedSecurities < summary.issuedSecurities}">
                <span> {{ tokensDisplay(summary.authorizedSecurities) }}</span>
                <span v-if="isTokenRepresentsDebtValue"> {{ mainCurrency }}</span>
              </strong>
            </h2>
            <small class="text-muted">{{ $t('controlBookTopSection.label.numberOfAuthorizedSecurities', [labelPrefix]) }}</small>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body text-center">
            <h2 class="mb-1">
              <strong>
                <span> {{ tokensDisplay(summary.totalCbSecurities) }}</span>
                <span v-if="isTokenRepresentsDebtValue"> {{ mainCurrency }}</span>
              </strong>
            </h2>
            <small class="text-muted">{{ $t('controlBookTopSection.label.numberOfIssuedSecuritiesCb', [labelPrefix]) }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-md-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body text-center">
            <h2 class="mb-1">
              <strong>
                <span> {{ tokensDisplay(summary.issuedSecurities) }}</span>
                <span v-if="isTokenRepresentsDebtValue"> {{ mainCurrency }}</span>
              </strong>
            </h2>
            <small class="text-muted">{{ $t('controlBookTopSection.label.numberOfIssuedSecuritiesMsf', [labelPrefix]) }}</small>
          </div>
          <hr class="my-0 mx-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 text-center">
                <h5 class="mb-1">
                  <span>{{ tokensDisplay(summary.totalIssuedToBlockchain) }}</span>
                </h5>
                <small class="text-muted">{{ $t('controlBookTopSection.label.totalIssuedToBlockchain' + debtSuffix) }}</small>
              </div>
              <div class="col-md-4 text-center">
                <h5 class="mb-1 mt-4 mt-md-0">
                  <span>{{ tokensDisplay(summary.totalIssuedToDRSTokens) }}</span>
                </h5>
                <small class="text-muted">{{ $t('controlBookTopSection.label.totalIssuedToDrsSecurities' + debtSuffix) }}</small>
              </div>
              <div class="col-md-4 text-center">
                <h5 class="mb-1 mt-4 mt-md-0">
                  <span>{{ tokensDisplay(summary.totalIssuedTokenBonus) }}</span>
                </h5>
                <small class="text-muted">{{ $t('controlBookTopSection.label.totalWhenIssuedSecurities' + debtSuffix) }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'CpControlBookTopSection',
  components: {},
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    ...mapGetters('controlBook', ['summary']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    ...mapState('issuersInfo', ['currentToken']),
    isTokenRepresentsDebtValue() {
      return this.currentToken && this.currentToken.representDebt;
    },
    mainCurrency() {
      return this.issuerInfo && this.issuerInfo.mainCurrency;
    },
    labelPrefix() {
      return this.isTokenRepresentsDebtValue
        ? this.$t('controlBookTopSection.label.principalAmount')
        : this.$t('controlBookTopSection.label.number');
    },
    debtSuffix() {
      return this.isTokenRepresentsDebtValue ? 'Debt' : '';
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        const { idIssuer: issuerId, tokenId } = this.$route.params;
        await this.fetchSummary({ issuerId, tokenId });
        this.isReady = true;
      },
    },
  },
  methods: {
    ...mapActions('controlBook', ['fetchSummary']),
    tokensDisplay(data) {
      if (typeof data === 'number') {
        return this.$options.filters.exponentialToDecimal(data);
      }
      return '-';
    },
  },

};
</script>

<style lang="scss" scoped>
  .color-red {
    color: #f7321e;
  }
</style>
