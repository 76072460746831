<template>
  <div class="card mt-4">
    <div class="card-header">
      <div class="row">
        <div class="col-12 d-md-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="$refs.createModal.$refs.controlBookCreateModal.show()"
          >
            {{ $t('controlBook.button.controlBook.update') }}
          </button>
        </div>
      </div>
    </div>

    <cp-table
      ref="cpTable"
      get-data-action="controlBook/getIssuerControlBookRecords"
      :url-params="urlParams"
      :fields="dynamicFields"
      without-search
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>
      <template
        slot="authoriser"
        slot-scope="{ rowData }"
      >
        {{ getAuthoriser(rowData.item.authoriser) }}
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
      </template>
    </cp-table>

    <create-modal
      ref="createModal"
      @onSubmit="updateAuthSecurities"
    />
    <edit-modal
      ref="editModal"
      :edit-data="editData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import { defaultFields, tokenIsDebtValueLabels } from '../fields';
import EditModal from './editModal';
import CreateModal from './createModal';

export default {
  name: 'ControlBookTable',
  components: {
    CpTable,
    EditModal,
    CreateModal,
  },
  data() {
    return {
      defaultFields,
      tokenIsDebtValueLabels,
      editData: {},
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    dynamicFields() {
      if (this.currentToken && this.currentToken.representDebt) {
        return this.defaultFields.map(currentField => ({
          ...currentField,
          i18nKey: this.tokenIsDebtValueLabels[currentField.key] || currentField.i18nKey,
        }));
      }
      return this.defaultFields;
    },
    urlParams() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      return { issuerId, tokenId };
    },
  },
  methods: {
    ...mapActions('controlBook', ['updateSecurities', 'updateRecord']),
    toEditModal(item) {
      this.editData = JSON.parse(JSON.stringify(item));
      this.$refs.editModal.$refs.controlBookEditModal.show();
    },

    updateTable() {
      this.$refs.cpTable.updateTableData();
    },

    updateAuthSecurities(data) {
      this.updateSecurities({
        ...data,
        ...this.urlParams,
      }).then(() => {
        this.updateTable();
      }).finally(() => {
        this.$refs.createModal.$refs.controlBookCreateModal.hide();
      });
    },

    saveChanges(item) {
      const { id: recordId, ...data } = item;
      this.updateRecord({
        ...this.urlParams,
        data,
        recordId,
      }).then(() => {
        this.updateTable();
        this.$refs.editModal.$refs.controlBookEditModal.hide();
      });
    },

    getAuthoriser(authoriser) {
      if (authoriser === 'me') {
        return this.$t('controlBook.label.me');
      }
      return authoriser;
    },
  },
};
</script>

<style lang="scss">
  .card-header {
    border-bottom: none;
  }
  .table {
    tr {
      td:nth-child(6) {
        max-width: 400px;
      }
    }
  }
</style>
