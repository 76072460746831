<template>
  <cp-general-modal
    ref="controlBookCreateModal"
    :title="$t('controlBook.controlBookCreateModal.title')"
    @onOk="handleSubmit"
    @shown="setInitial"
  >
    <cp-input
      v-model="model.authoriser"
      name="authoriser"
      :label="$t('controlBook.controlBookCreateModal.input.authorizer')"
    />
    <cp-input
      v-model="model.authorizedSecurities"
      :validate="`required|min_value:0`"
      :html-props="{min:0}"
      name="authorizedSecurities"
      :label="$t('controlBook.controlBookCreateModal.input.authorizedSecurities' + debtSuffix)"
      input-type="number"
    />
    <cp-input
      v-model="model.cbSecurities"
      :validate="`required|min_value:0`"
      :html-props="{min:0}"
      name="cbSecurities"
      :label="$t('controlBook.controlBookCreateModal.input.cbSecurities' + debtSuffix)"
      input-type="number"
    />
    <cp-input
      v-model="model.description"
      name="description"
      :label="$t('controlBook.controlBookCreateModal.input.description')"
    />
    <cp-input
      v-model="model.documentationId"
      name="documentationId"
      :label="$t('controlBook.controlBookCreateModal.input.documentationId')"
    />
  </cp-general-modal>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'ControlBookCreateModal',
  components: {
    CpInput,
    CpGeneralModal,
  },
  data() {
    return {
      model: {},
      initialState: {
        authoriser: '',
        authorizedSecurities: 0,
        cbSecurities: null,
        description: '',
        documentationId: moment(new Date()).format('CB-YYYY-MM-DD'),
      },
    };
  },
  computed: {
    ...mapGetters('controlBook', ['summary']),
    ...mapState('issuersInfo', ['currentToken']),
    debtSuffix() {
      return this.currentToken && this.currentToken.representDebt ? 'Debt' : '';
    },
  },
  methods: {
    setInitial() {
      if (this.summary && this.summary.authorizedSecurities) {
        this.initialState.authorizedSecurities = this.summary.authorizedSecurities;
        this.initialState.cbSecurities = this.summary.totalCbSecurities;
      }
      this.model = Object.assign({}, this.initialState);
    },
    handleSubmit() {
      this.$emit('onSubmit', this.model);
    },
  },
};
</script>
