<template>
  <div>
    <cp-page-head :page-name="$t('controlBook.title')" />
    <cp-top-section />
    <cp-control-book-table />
  </div>
</template>

<script>
import CpPageHead from '../transfer-agent-head';
import CpTopSection from '../topSection';
import CpControlBookTable from './components/table';

export default {
  name: 'CpControlBook',
  metaInfo: {
    title: 'Control Book',
  },
  components: {
    CpTopSection,
    CpPageHead,
    CpControlBookTable,
  },
};
</script>
