<template>
  <cp-general-modal
    ref="controlBookEditModal"
    :title="$t('controlBook.controlBookEditModal.title')"
    title-icon="ion ion-md-create"
    :form-group-value="editData"
    @onOk="handleSubmit"
  >
    <div class="table-responsive">
      <table class="table card-table table-striped">
        <tbody>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.createdAt') }}</strong></td>
            <td>{{ model.createdAt | dateAndTimeFilter }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.authorizer') }}</strong></td>
            <td>
              <cp-input
                v-model="model.authoriser"
                name="authoriser"
              />
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.operatorName') }}</strong></td>
            <td>{{ (model.operator && model.operator.name) || '-' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.previousAuthorizedSecurities' + debtSuffix) }}</strong></td>
            <td>{{ model.previousAuthorizedSecurities }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.authorizedSecurities' + debtSuffix) }}</strong></td>
            <td>{{ model.authorizedSecurities }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.previousCbSecurities') }}</strong></td>
            <td>{{ model.previousCbSecurities }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.cbSecurities' + debtSuffix) }}</strong></td>
            <td>{{ model.cbSecurities }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.description') }}</strong></td>
            <td>
              <cp-input
                v-model="model.description"
                name="description"
              />
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('controlBook.controlBookEditModal.input.documentationId') }}</strong></td>
            <td>
              <cp-input
                v-model="model.documentationId"
                name="documentationId"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapState } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'ControlBookEditModal',
  components: {
    CpInput,
    CpGeneralModal,
  },
  props: {
    editData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    debtSuffix() {
      return this.currentToken && this.currentToken.representDebt ? 'Debt' : '';
    },
  },
  watch: {
    editData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('onSubmit', this.model);
    },
  },
};
</script>
